import useTranslation from 'next-translate/useTranslation'
import BodyText from '@ancon/wildcat-ui/web/orderweb/BodyText'
import UserProfileIcon from '@ancon/wildcat-ui/shared/icons/circle-user-outline.svg'

import Modal from '../../app/components/modal/Modal'
import CompanySignUpForm from '../forms/companySignUp/CompanySignUpForm'
import useAppSelector from '../../../store/hooks/useAppSelector'
import { authCompanySignUpModalVisibleSelector } from '../store/authSelectors'
import useAppDispatch from '../../../store/hooks/useAppDispatch'
import {
  setAuthCompanySignUpModalVisible,
  showAuthModal,
} from '../store/authSlice'
import Button from '../../app/components/Button'
import { AuthModalType } from '../types'

import styles from './CompanySignUpModal.module.scss'
import Separator from './authModal/Separator'

export default function CompanySignUpModal() {
  const { t } = useTranslation('common')
  const dispatch = useAppDispatch()

  const isOpen = useAppSelector(authCompanySignUpModalVisibleSelector)

  function handleOnCloseClick() {
    dispatch(setAuthCompanySignUpModalVisible(false))
  }

  function handleAccountSignUpClick() {
    dispatch(setAuthCompanySignUpModalVisible(false))
    dispatch(showAuthModal(AuthModalType.SignUp))
  }

  return (
    <Modal
      isOpen={isOpen}
      mobileModalMode="bottom-sheet"
      title={t('companySignUpModal.title')}
      onClose={handleOnCloseClick}
    >
      <div className={styles.container}>
        <BodyText
          color="body-1"
          fontSize="1.6rem"
          className={styles.description}
        >
          {t('companySignUpModal.infoMessage', {
            brandName: t('generated:brandName'),
          })}
        </BodyText>
        <CompanySignUpForm />
        <Separator />
        <div className={styles.accountSignUpOption}>
          <div className={styles.textRow}>
            <UserProfileIcon />
            <BodyText>
              {t('companySignUpModal.personalAccountQuestion')}
            </BodyText>
          </div>
          <Button variant="secondary" onClick={handleAccountSignUpClick}>
            {t('companySignUpModal.createPersonalAccount')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
