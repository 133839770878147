import clsx from 'clsx'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import CloseIcon from '@ancon/wildcat-ui/shared/icons/close.svg'
import ShoppingBagOutlineIcon from '@ancon/wildcat-ui/shared/icons/shopping-bag-outline.svg'
import EditAccountIcon from '@ancon/wildcat-ui/shared/icons/pen-line-outline.svg'
import GlobeIcon from '@ancon/wildcat-ui/shared/icons/globe-outline.svg'

import Button from '../../../../app/components/Button'
import HeadingText from '../../../../app/components/HeadingText'
import BodyText from '../../../../app/components/BodyText'
import useAppSelector from '../../../../../store/hooks/useAppSelector'
import { appIsMobileDrawerVisibleSelector } from '../../../../app/store/appSelectors'
import useAppDispatch from '../../../../../store/hooks/useAppDispatch'
import {
  appSetIsMobileDrawerVisible,
  appSetIsSwitchLanguageModalVisible,
} from '../../../../app/store/appSlice'
import {
  clientContextCurrentUserCompanyNameSelector,
  clientContextCustomerNameSelector,
  clientContextIsAnonymousCustomerSelector,
  clientContextProfileNameSelector,
} from '../../../../clientContext/store/clientContextSelectors'
import {
  authCustomerIsLoggedInSelector,
  authSignOutPendingSelector,
} from '../../../../auth/store/authSelectors'
import isAppWhiteLabeled from '../../../../app/utils/isAppWhiteLabeled'
import {
  setAuthGuestCustomerOnboardingModalVisible,
  showAuthModal,
} from '../../../../auth/store/authSlice'
import { signOut } from '../../../../auth/store/authThunks'
import InteractiveDiv from '../../InteractiveDiv'
import { AppRoutes } from '../../../../app/constants'
import useRouteMatch from '../../../../app/hooks/useRouteMatch'
import AuthProfileTypeIcon from '../../../../auth/components/AuthProfileTypeIcon'
import { AuthModalType, AuthProfileType } from '../../../../auth/types'
import { preOrderUserSelector } from '../../../../preOrder/store/preOrderSelectors'
import { resetPreOrderState } from '../../../../preOrder/store/preOrderSlice'
import storedPreOrderUserToken from '../../../../preOrder/utils/storedPreOrderUserToken'
import localStorageUtils from '../../../../app/utils/localStorageUtils'

import styles from './MobileSideDrawer.module.scss'

export default function MobileSideDrawer() {
  const { t } = useTranslation('common')
  const dispatch = useAppDispatch()

  const router = useRouter()

  const isDrawerVisible = useAppSelector(appIsMobileDrawerVisibleSelector)

  const isSignOutPending = useAppSelector(authSignOutPendingSelector)

  const customerFullName = useAppSelector(clientContextCustomerNameSelector)

  const isAnonymousCustomer = useAppSelector(
    clientContextIsAnonymousCustomerSelector,
  )

  const isLoggedIn = useAppSelector(authCustomerIsLoggedInSelector)

  const companyName = useAppSelector(
    clientContextCurrentUserCompanyNameSelector,
  )

  const profileName = useAppSelector(clientContextProfileNameSelector)

  const preOrderUser = useAppSelector(preOrderUserSelector)

  const isGiftCardPage = useRouteMatch([AppRoutes.GiftCard])

  const isPreOrderRoute = useRouteMatch([
    AppRoutes.Checkout,
    AppRoutes.PreOrder,
  ])

  const isWiteLabeledApp = isAppWhiteLabeled()

  function handleCloseDrawer() {
    dispatch(appSetIsMobileDrawerVisible(false))
  }

  function handleSignInClick() {
    handleCloseDrawer()
    dispatch(showAuthModal(AuthModalType.SignIn))
  }

  function handleSignUpClick() {
    handleCloseDrawer()
    dispatch(showAuthModal(AuthModalType.SignUp))
  }

  function handleNavigateToMyOrdersClick() {
    handleCloseDrawer()
    router.push(AppRoutes.Orders)
  }

  function handleNavigateToManageAccountClick() {
    handleCloseDrawer()
    router.push(AppRoutes.Account)
  }

  function handleCreateProfileClick() {
    handleCloseDrawer()
    dispatch(setAuthGuestCustomerOnboardingModalVisible(true))
  }

  function handleSignOutClick() {
    if (preOrderUser) {
      if (isLoggedIn) {
        dispatch(signOut())
      }
      // Reset Pre Order state and remove stored token
      dispatch(resetPreOrderState())
      storedPreOrderUserToken.remove()
      localStorageUtils.removeItem('cartMeta')
      // Redirect to home if user is on Pre Order page
      if (isPreOrderRoute) {
        router.replace(AppRoutes.Home)
      }
    } else {
      dispatch(signOut())
    }
    handleCloseDrawer()
  }

  function handleSwitchLanguageClick() {
    dispatch(appSetIsSwitchLanguageModalVisible(true))
  }

  useEffect(() => {
    if (isDrawerVisible) {
      document.body.classList.add(styles.isOpen)
    } else {
      document.body.classList.remove(styles.isOpen)
    }
  }, [isDrawerVisible])

  function getProfileName() {
    if (!isLoggedIn && preOrderUser) {
      return preOrderUser.name
    }
    if (isLoggedIn) {
      return profileName
    }
    return t('auth.hiThere')
  }

  return (
    <div
      className={clsx(styles.container, {
        [styles.isDrawerVisible]: isDrawerVisible,
      })}
    >
      <div className={styles.headerContainer}>
        <div className={styles.userInfoContainer}>
          <AuthProfileTypeIcon
            type={
              companyName ? AuthProfileType.Company : AuthProfileType.Customer
            }
          />
          <div className={styles.userMetaContainer}>
            <HeadingText as="h2">{getProfileName()}</HeadingText>
            {isLoggedIn && (
              <BodyText color="body-1">
                {(!!companyName && customerFullName) ||
                  (isAnonymousCustomer && t('auth.guest'))}
              </BodyText>
            )}
          </div>
        </div>
        <Button variant="secondary" onClick={handleCloseDrawer}>
          <CloseIcon />
        </Button>
      </div>
      <div className={styles.contentContainer}>
        {!isGiftCardPage && !isLoggedIn && !preOrderUser && (
          <div className={styles.authActionsContainer}>
            <Button onClick={handleSignInClick}>{t('auth.signIn')}</Button>
            <Button variant="secondary" onClick={handleSignUpClick}>
              {t('auth.signUp')}
            </Button>
          </div>
        )}

        {!isGiftCardPage && isLoggedIn && (
          <div className={styles.navigationList}>
            <InteractiveDiv
              className={styles.navItem}
              onClick={handleNavigateToMyOrdersClick}
            >
              <ShoppingBagOutlineIcon />
              <BodyText> {t('components.navBar.profile.myOrders')}</BodyText>
            </InteractiveDiv>
            <InteractiveDiv
              className={clsx(styles.navItem, {
                [styles.disabled]: isAnonymousCustomer,
              })}
              onClick={handleNavigateToManageAccountClick}
              disabled={isAnonymousCustomer}
            >
              <EditAccountIcon />
              <BodyText>{t('auth.manageAccount')}</BodyText>
            </InteractiveDiv>
          </div>
        )}
        {!isGiftCardPage && (isLoggedIn || preOrderUser) && (
          <div className={styles.accountActionsContainer}>
            {isAnonymousCustomer && (
              <InteractiveDiv
                className={styles.actionItem}
                onClick={handleCreateProfileClick}
              >
                <BodyText>
                  {t(
                    isWiteLabeledApp
                      ? 'auth.createProfile'
                      : 'auth.createAnconProfile',
                  )}
                </BodyText>
              </InteractiveDiv>
            )}
            {!isAnonymousCustomer && (
              <InteractiveDiv
                className={styles.actionItem}
                onClick={handleSignOutClick}
                disabled={isSignOutPending}
              >
                <BodyText onClick={handleSignOutClick}>
                  {t(
                    !isLoggedIn && preOrderUser
                      ? 'components.navBar.profile.exitSession'
                      : 'components.navBar.profile.signOut',
                  )}
                </BodyText>
              </InteractiveDiv>
            )}
          </div>
        )}
      </div>

      <div className={styles.footerContainer}>
        <InteractiveDiv
          className={styles.footerItem}
          onClick={handleSwitchLanguageClick}
        >
          <GlobeIcon />
          <BodyText>{t('components.navBar.switchLanguage')}</BodyText>
        </InteractiveDiv>
      </div>
    </div>
  )
}
