require('moment/locale/en-gb') // English
require('moment/locale/nb') // Norweigan
require('moment/locale/sv') // Swedish

/** @type {import('next-translate').I18nConfig} */
const i18nConfig = {
  locales: ['sv', 'en', 'nb'],
  defaultLocale: 'sv',
  loadLocaleFrom(lang, ns) {
    return import(`./src/features/locale/locales/${lang}/${ns}.json`).then(
      m => m.default,
    )
  },
  pages: {
    '*': ['common', 'generated'],
    '/': ['home'],
    '/outlets': ['outlets', 'preOrder'],
    '/outlet/[outletSlug]': ['outlet', 'preOrder'],
    '/checkout': ['checkout'],
    '/checkout/result': ['result', 'checkout'],
    '/order-status/[outletId]/[checkoutId]': ['orderStatus', 'checkout'],
    '/account': ['account'],
    '/orders': ['orders', 'checkout'],
    '/order-status/qr/[incrementalId]': ['orderStatus'],
    '/giftcard/[giftCardShareKey]': ['giftCards'],
    '/pre-order/[preOrderId]': ['preOrder'],
    '/pre-order/manage': ['preOrder'],
    '/pre-order/status/[outletId]/[preOrderId]': [
      'orderStatus',
      'checkout',
      'preOrder',
    ],
  },
}

module.exports = i18nConfig
