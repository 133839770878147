import useTranslation from 'next-translate/useTranslation'
import { useFormContext } from 'react-hook-form'
import { useMemo } from 'react'
import { CountryCode } from '@ancon/wildcat-types'

import { CompanySignUpFormState } from '../../types'
import companyConstraints from '../../../company/constraints/companyConstraints'
import FormInput from '../../../app/components/FormInput'
import FormDropdown from '../../../app/components/FormDropdown'
import HeadingText from '../../../app/components/HeadingText'
import userConstraints from '../../../app/constraints/userConstraints'

import styles from './CompanySignUpFieldSet.module.scss'

const availableCountries = [CountryCode.Sweden, CountryCode.Norway]

export default function CompanyInvoicingDetails() {
  const { t } = useTranslation('common')

  const { formState } = useFormContext<CompanySignUpFormState>()

  const { isSubmitting } = formState

  const countryOptions = useMemo(
    () =>
      availableCountries.map(country => ({
        label: t(`companySignUpModal.country.${country}`),
        value: country,
      })),
    [t],
  )

  return (
    <section>
      <HeadingText as="h3" className={styles.fieldSetTitle}>
        {t('companySignUpModal.invoicingAddress')}
      </HeadingText>
      <div className={styles.container}>
        <div className={styles.row}>
          <FormInput
            fieldName="street"
            autoComplete="off"
            autoCapitalize="none"
            label={t('companySignUpModal.street')}
            type="text"
            rules={{
              required: t('validations.required'),
              maxLength: {
                value: companyConstraints.max.street,
                message: t('validations.maxLength', {
                  limit: companyConstraints.max.street,
                }),
              },
            }}
            readOnly={isSubmitting}
            containerClassName={styles.flexContainer}
            required
          />
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <FormInput
              fieldName="zipCode"
              autoComplete="off"
              autoCapitalize="none"
              label={t('companySignUpModal.zipCode')}
              placeholder={t('companySignUpModal.placeholders.zipCode')}
              type="text"
              rules={{
                required: t('validations.required'),
                pattern: {
                  message: t('validations.invalid'),
                  value: companyConstraints.pattern.zipCode,
                },
                maxLength: {
                  value: companyConstraints.max.zipCode,
                  message: t('validations.maxLength', {
                    limit: companyConstraints.max.zipCode,
                  }),
                },
              }}
              readOnly={isSubmitting}
              required
            />
          </div>
          <div className={styles.column}>
            <FormInput
              fieldName="city"
              autoComplete="off"
              autoCapitalize="none"
              label={t('companySignUpModal.city')}
              type="text"
              rules={{
                required: t('validations.required'),
                maxLength: {
                  value: companyConstraints.max.city,
                  message: t('validations.maxLength', {
                    limit: companyConstraints.max.city,
                  }),
                },
              }}
              readOnly={isSubmitting}
              required
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <FormDropdown
              fieldName="country"
              label={t('companySignUpModal.country.country')}
              options={countryOptions}
            />
          </div>
          <div className={styles.column}>
            <FormInput
              fieldName="invoiceEmail"
              autoComplete="off"
              autoCapitalize="none"
              label={t('companySignUpModal.invoicingEmail')}
              type="email"
              rules={{
                required: t('validations.required'),
                pattern: {
                  message: t('validations.invalidEmail'),
                  value: userConstraints.pattern.email,
                },
                maxLength: {
                  value: userConstraints.max.email,
                  message: t('validations.maxLength', {
                    limit: userConstraints.max.email,
                  }),
                },
              }}
              readOnly={isSubmitting}
              required
            />
          </div>
        </div>
      </div>
    </section>
  )
}
