import { createSlice } from '@reduxjs/toolkit'

import {
  OutletSearchModalType,
  OutletsReducerState,
  RestaurantSelectionModalType,
} from '../types'
import { validatePreSelectedOutlet } from '../../outlet/store/outletThunks'

import { outletsReducers } from './outletsReducers'
import { outletListEntityAdapter } from './outletsEntityAdapters'
import { fetchNearbyOutlets } from './outletsThunks'

const outletsInitialState: OutletsReducerState = {
  list: outletListEntityAdapter.getInitialState(),
  offset: 0,
  hasMore: true,
  outletsFilters: {
    location: null,
    searchTerm: null,
  },
  // Request status
  outletListFetchPending: false,

  // Modal
  visibleOutletSearchModal: OutletSearchModalType.None,
  visibleOrderFormatSelectionModal: false,

  // Selected outlet
  selectedOutletListItem: null,

  // WL company user restaurant selection
  selectedRestaurantId: null,
  restaurantSelectionModalVisibleType: RestaurantSelectionModalType.None,
}

const outletsSlice = createSlice({
  name: 'outletsReducer',
  initialState: outletsInitialState,
  reducers: outletsReducers,
  extraReducers(builder) {
    // Fetch nearby outlets
    builder.addCase(fetchNearbyOutlets.pending, (state, action) => {
      const { offset, keepExisting } = action.meta.arg

      state.outletListFetchPending = true

      if (offset === 0 && !keepExisting) {
        state.list = outletListEntityAdapter.removeAll(state.list)
      }
    })
    builder.addCase(fetchNearbyOutlets.fulfilled, (state, action) => {
      const { offset, limit } = action.meta.arg
      const { items, meta } = action.payload

      state.hasMore = meta.offset + limit < meta.count

      if (offset) {
        outletListEntityAdapter.addMany(state.list, items)
      } else {
        outletListEntityAdapter.setAll(state.list, items)
      }

      state.offset = state.list.ids.length
      state.outletListFetchPending = false
    })
    builder.addCase(fetchNearbyOutlets.rejected, state => {
      state.outletListFetchPending = false
    })
    // Validate pre-selected outlet ID
    builder.addCase(validatePreSelectedOutlet.pending, (state, action) => {
      state.selectedRestaurantId = action.meta.arg.outletId
    })
    builder.addCase(validatePreSelectedOutlet.fulfilled, (state, action) => {
      state.selectedOutletListItem = action.payload
    })
    builder.addCase(validatePreSelectedOutlet.rejected, state => {
      state.selectedRestaurantId = null
    })
  },
})

export const {
  outletsSetOutletsFilters,
  outletsSetVisibleOutletSearchModal,
  outletsSetSelectedOutletListItem,
  outletsSetVisibleOrderFormatSelectionModal,
  outletsSetSelectedRestaurantId,
  setRestaurantSelectionModalVisible,
} = outletsSlice.actions

const outletsReducer = outletsSlice.reducer

export default outletsReducer
