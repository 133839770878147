import { useMemo } from 'react'
import useTranslation from 'next-translate/useTranslation'
import clsx from 'clsx'
import { useRouter } from 'next/router'

import BodyText from '../../../../app/components/BodyText'
import {
  clientContextCustomerNameSelector,
  clientContextHasCompanyProfilesSelector,
  clientContextHasProfileNameSelector,
  clientContextIsAnonymousCustomerSelector,
  clientContextIsCompanyUserSelector,
  clientContextProfileNameSelector,
} from '../../../../clientContext/store/clientContextSelectors'
import Button from '../../../../app/components/Button'
import { signOut } from '../../../../auth/store/authThunks'
import useAppSelector from '../../../../../store/hooks/useAppSelector'
import useAppDispatch from '../../../../../store/hooks/useAppDispatch'
import {
  authCurrentUserProfileTypeSelector,
  authCustomerIsLoggedInSelector,
  authSignOutPendingSelector,
} from '../../../../auth/store/authSelectors'
import Dropdown from '../../../../app/components/dropdown/Dropdown'
import { DropdownButtonProps } from '../../../../app/components/dropdown/DropdownButton'
import DropdownIcon from '../../../../app/components/DropdownIcon'
import {
  setAuthProfileSelectionModalType,
  setAuthGuestCustomerOnboardingModalVisible,
} from '../../../../auth/store/authSlice'
import AuthProfileTypeIcon from '../../../../auth/components/AuthProfileTypeIcon'
import { AppRoutes } from '../../../../app/constants'
import isAppWhiteLabeled from '../../../../app/utils/isAppWhiteLabeled'
import useRouteMatch from '../../../../app/hooks/useRouteMatch'
import storedPreOrderUserToken from '../../../../preOrder/utils/storedPreOrderUserToken'
import { resetPreOrderState } from '../../../../preOrder/store/preOrderSlice'
import localStorageUtils from '../../../../app/utils/localStorageUtils'
import {
  preOrderUserNameSelector,
  preOrderUserSelector,
} from '../../../../preOrder/store/preOrderSelectors'
import { ProfileSelectionModalType } from '../../../../auth/types'

import styles from './NavbarUserProfile.module.scss'

enum ProfileOptions {
  MyOrders = 'MyOrders',
  MyAccount = 'MyAccount',
  ChangeProfile = 'ChangeProfile',
  SignOut = 'SignOut',
  GuestOnBoarding = 'GuestOnBoarding',
}

function ProfileDropdownButton({
  className,
  isOpen,
  onToggle,
}: DropdownButtonProps) {
  const signOutPending = useAppSelector(authSignOutPendingSelector)

  const customerName = useAppSelector(clientContextCustomerNameSelector)
  const profileName = useAppSelector(clientContextProfileNameSelector)
  const isCompanyUser = useAppSelector(clientContextIsCompanyUserSelector)
  const authProfileType = useAppSelector(authCurrentUserProfileTypeSelector)
  const preOrderUserName = useAppSelector(preOrderUserNameSelector)
  const clientContextHasProfileName = useAppSelector(
    clientContextHasProfileNameSelector,
  )

  const isOutletDetailsPage = useRouteMatch([`${AppRoutes.Outlet}/`])

  return (
    <Button
      size="large"
      variant="secondary"
      onClick={onToggle}
      disabled={signOutPending}
      className={className}
    >
      <AuthProfileTypeIcon
        type={authProfileType}
        className={styles.profileIcon}
      />
      <span
        className={clsx(styles.profileContent, {
          [styles.outletDetailsPage]: isOutletDetailsPage,
        })}
      >
        <BodyText as="span" className={styles.profileName}>
          {!clientContextHasProfileName && preOrderUserName
            ? preOrderUserName
            : profileName}
        </BodyText>
        {isCompanyUser && (
          <BodyText as="span" className={styles.profileUserName}>
            {customerName}
          </BodyText>
        )}
      </span>
      <DropdownIcon isOpen={isOpen} />
    </Button>
  )
}

export default function NavbarUserProfile() {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const isAnonymousCustomer = useAppSelector(
    clientContextIsAnonymousCustomerSelector,
  )
  const isLoggedIn = useAppSelector(authCustomerIsLoggedInSelector)
  const preOrderUser = useAppSelector(preOrderUserSelector)
  const isPreOrderRoute = useRouteMatch([
    AppRoutes.Checkout,
    AppRoutes.PreOrder,
  ])

  const { t } = useTranslation('common')

  const hasCompanyProfiles = useAppSelector(
    clientContextHasCompanyProfilesSelector,
  )

  const navBarProfileOptions = useMemo(() => {
    const options = [
      !!isLoggedIn && {
        label: t('components.navBar.profile.myOrders'),
        value: ProfileOptions.MyOrders,
      },
      !!isLoggedIn &&
        !isAnonymousCustomer && {
          label: t('components.navBar.profile.myAccount'),
          value: ProfileOptions.MyAccount,
        },
      hasCompanyProfiles && {
        label: t('components.navBar.profile.changeProfile'),
        value: ProfileOptions.ChangeProfile,
      },
      isAnonymousCustomer &&
        (!preOrderUser || preOrderUser?.isHost === false) && {
          label: t(
            isAppWhiteLabeled()
              ? 'auth.createProfile'
              : 'auth.createAnconProfile',
          ),
          value: ProfileOptions.GuestOnBoarding,
        },
      {
        label: t(
          isAnonymousCustomer || (preOrderUser && !isLoggedIn)
            ? 'components.navBar.profile.exitSession'
            : 'components.navBar.profile.signOut',
        ),
        value: ProfileOptions.SignOut,
      },
    ]

    return options.filter(Boolean) as Array<{
      label: string
      value: ProfileOptions
    }>
  }, [hasCompanyProfiles, isAnonymousCustomer, preOrderUser, isLoggedIn, t])

  function handleClickOption(option: string) {
    switch (option) {
      case ProfileOptions.MyOrders:
        router.push(AppRoutes.Orders)
        break

      case ProfileOptions.MyAccount:
        router.push(AppRoutes.Account)
        break

      case ProfileOptions.ChangeProfile:
        dispatch(
          setAuthProfileSelectionModalType(
            ProfileSelectionModalType.ProfileSelection,
          ),
        )
        break

      case ProfileOptions.SignOut:
        if (preOrderUser) {
          if (isLoggedIn) {
            dispatch(signOut())
          }
          // Reset Pre Order state and remove stored token
          dispatch(resetPreOrderState())
          storedPreOrderUserToken.remove()
          localStorageUtils.removeItem('cartMeta')
          // Redirect to home if user is on Pre Order page
          if (isPreOrderRoute) {
            router.replace(AppRoutes.Home)
          }
        } else {
          dispatch(signOut())
        }

        break

      case ProfileOptions.GuestOnBoarding:
        dispatch(setAuthGuestCustomerOnboardingModalVisible(true))
        break

      default:
        break
    }
  }

  return (
    <Dropdown
      CustomDropdownButton={ProfileDropdownButton}
      popupPlacement="top-end"
      popupClassName={clsx(styles.profileDropdownPopup, {
        [styles.guestCustomer]: isAnonymousCustomer,
      })}
      onClickOption={handleClickOption}
      options={navBarProfileOptions}
    />
  )
}
