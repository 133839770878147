import {
  CheckoutOrderType,
  CheckoutStatus,
  OrderFormat,
  PreOrderStatus,
} from '@ancon/wildcat-types'
import moment, { Moment } from 'moment'

import getTenantIds from '../../app/utils/getTenantIds'

const FilterStatus = [
  `in: ${CheckoutStatus.Created}`,
  `in: ${CheckoutStatus.Ready}`,
  `in: ${CheckoutStatus.Authorized}`,
  `in: ${CheckoutStatus.Received}`,
  `in: ${CheckoutStatus.Captured}`,
  `in: ${CheckoutStatus.InProgress}`,
  `in: ${CheckoutStatus.ReadyToCollect}`,
]

const FilterOrderFormat = [
  `in:${OrderFormat.EatIn}`,
  `in:${OrderFormat.Delivery}`,
  `in:${OrderFormat.TakeAway}`,
  `in:${OrderFormat.TableOrder}`,
  `in:${OrderFormat.BoxPickup}`,
]

const OngoingOrderTypes = [
  CheckoutOrderType.Default,
  CheckoutOrderType.PreOrder,
].map(orderType => `in:${orderType}`)

const UpcomingOrderTypes = [
  CheckoutOrderType.PreOrderLink,
  CheckoutOrderType.GroupPreOrder,
].map(orderType => `in:${orderType}`)

const preOrderStatus = [
  `in:${PreOrderStatus.Created}`,
  `in:${PreOrderStatus.ApprovalPending}`,
  `in:${PreOrderStatus.Activated}`,
  `in:${PreOrderStatus.Started}`,
  `in:${PreOrderStatus.Waiting}`,
  `in:${PreOrderStatus.Pending}`,
]

function getUtcDateTime(dateTime: Moment) {
  return dateTime.utc().format('YYYY-MM-DDTHH:mm:ss')
}

function getCreatedAtFilter() {
  const CheckoutsFrom = getUtcDateTime(moment().subtract(3, 'hours'))
  const CheckoutsTo = getUtcDateTime(moment().add(3, 'hours'))

  const createdAt = [`gt:${CheckoutsFrom}`, `lt:${CheckoutsTo}`]

  return createdAt
}

export default function generateActiveOrdersFilters(isPreOrders = false) {
  const tenantIds = getTenantIds()

  const commonFilters = {
    status: FilterStatus,
    orderFormat: FilterOrderFormat,
    ...(tenantIds?.length && {
      'tenant.id': tenantIds.map((id: string) => `in:${id}`),
    }),
  }

  if (isPreOrders) {
    return {
      ...commonFilters,
      orderType: UpcomingOrderTypes,
      preOrderStatus,
    }
  }

  const createdAt = getCreatedAtFilter()

  return {
    ...commonFilters,
    createdAt,
    orderType: OngoingOrderTypes,
  }
}
