import {
  CheckoutDetails,
  CustomerCheckoutListItem,
  CustomerOrder,
} from '@ancon/wildcat-types'
import { createEntityAdapter } from '@reduxjs/toolkit'

const activeOrdersEntityAdapter = createEntityAdapter<CheckoutDetails>()
const upcomingOrdersEntityAdapter =
  createEntityAdapter<CustomerCheckoutListItem>()
const pastOrdersEntityAdapter = createEntityAdapter<CustomerOrder>()

export {
  activeOrdersEntityAdapter,
  pastOrdersEntityAdapter,
  upcomingOrdersEntityAdapter,
}
