import { AxiosError } from 'axios'
import { ErrorCode } from '@ancon/wildcat-types'

import { CheckoutOutOfStockAdditionalData } from '../checkout/types'
import { ProductPriceChangeAdditionalData } from '../../api/endpoints/core/checkouts'

export type CodedError = {
  code: ErrorCode
  message?: string
  additionalData: Record<string, unknown>
}

export interface ErrorResponseAdditionalData
  extends Record<ErrorCode, unknown> {
  [ErrorCode.PaymentAcceptOutOfStock]: CheckoutOutOfStockAdditionalData[]
  [ErrorCode.CheckoutPriceChanged]: ProductPriceChangeAdditionalData[]
}

export type APIErrorResponse<T extends ErrorCode = never> = {
  errorCode?: ErrorCode
  message?: string
  additionalData?: ErrorResponseAdditionalData[T]
}

export type AppGenericErrorType =
  | AxiosError<{ error: { id: string } }>
  | AxiosError<APIErrorResponse>
  | CodedError
  | unknown

export enum ErrorModalType {
  CheckoutItemPriceChanged,
  CreateCheckoutProductOutOfStock,
  UpdateCheckoutProductOutOfStock,
  UpdateCheckoutItemQuantityOutOfStock,
  GeneralCheckoutError,
  ProductOutOfStock,
  OutletNotAvailableForOrders,
  CheckoutPaymentError,
  CheckoutFetchError,
}

export type AppError = {
  name: string
  message: string
}

export type ErrorInfo = {
  title: string
  message: string
}

export type InformativeError<T extends ErrorCode = never> = ErrorInfo & {
  errorModalType?: ErrorModalType
  additionalData?: ErrorResponseAdditionalData[T]
}

export type AppReducerState = {
  isErrorModalVisible: boolean
  errorModalConfig: InformativeError | null

  language: string

  isCartDrawerVisible: boolean
  isMobileDrawerVisible: boolean
  isSwitchLanguageModalVisible: boolean
  orderDrawerOrderId: string | null

  isSignalROnline: boolean
}
