import config from '../../config/generated.json'
import { EnvConfig } from '../../config/types'

export default function getTenantIds(includeTestTenants = true) {
  const envConfig = config.envConfig as EnvConfig

  const tenantIds: string[] = envConfig?.tenantIds?.length
    ? envConfig.tenantIds
    : []

  if (includeTestTenants && envConfig?.testingTenantIds?.length) {
    tenantIds.push(...envConfig.testingTenantIds)
  }

  return tenantIds
}
