import useTranslation from 'next-translate/useTranslation'
import { useFormContext } from 'react-hook-form'

import { CompanySignUpFormState } from '../../types'
import FormInput from '../../../app/components/FormInput'
import FormPhoneNumberInput from '../../../app/components/FormPhoneNumberInput'
import HeadingText from '../../../app/components/HeadingText'
import LoginFormInputs from '../LoginFormInputs'

import styles from './CompanySignUpFieldSet.module.scss'

export default function CompanyMemberDetails() {
  const { t } = useTranslation('common')

  const { formState } = useFormContext<CompanySignUpFormState>()

  const { isSubmitting } = formState

  return (
    <section>
      <HeadingText as="h3" className={styles.fieldSetTitle}>
        {t('companySignUpModal.companyMemberDetails')}
      </HeadingText>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.column}>
            <FormInput
              fieldName="firstName"
              autoComplete="off"
              autoCapitalize="none"
              label={t('companySignUpModal.firstName')}
              type="text"
              rules={{
                required: t('validations.required'),
              }}
              readOnly={isSubmitting}
              required
              placeholder={t('placeholders.firstName')}
            />
          </div>
          <div className={styles.column}>
            <FormInput
              fieldName="lastName"
              autoComplete="off"
              autoCapitalize="none"
              label={t('companySignUpModal.lastName')}
              type="text"
              rules={{
                required: t('validations.required'),
              }}
              readOnly={isSubmitting}
              required
              placeholder={t('placeholders.lastName')}
            />
          </div>
        </div>
        <div className={styles.row}>
          <LoginFormInputs inputContainerClassName={styles.flexContainer} />
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <FormPhoneNumberInput
              autoComplete="off"
              fieldName="telephoneNumber"
              label={t('companySignUpModal.phoneNumber')}
              readOnly={isSubmitting}
              rules={{
                required: t('validations.required'),
              }}
              required
              placeholder={t('placeholders.phoneNumber')}
            />
          </div>
          <div className={styles.column}>
            <FormInput
              fieldName="department"
              label={t('companySignUpModal.department')}
              type="text"
              readOnly={isSubmitting}
              placeholder={t('companySignUpModal.placeholders.department')}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
