import { OutletSearchListItem, SignInProvider } from '@ancon/wildcat-types'

export enum ProfileSelectionModalType {
  None,
  ProfileSelection,
  CompanySelection,
}

export type AuthReducerState = {
  clientSDKLoaded: boolean

  loggedIn: boolean | null

  // Sign In
  signInPending: boolean
  signInProvider: SignInProvider | null

  // Sign Up
  signUpPending: boolean

  // Social authentication
  socialSignUpPending: boolean

  // Auth Modal
  showAuthModal: boolean
  isSignUpModal: boolean
  showCompanySignUpModal: boolean
  showEditAccountModal: boolean
  showGuestCustomerOnboardingModal: boolean

  authModalType?: AuthModalType

  // Sign Out
  signOutPending: boolean

  profileSelectionModalType: ProfileSelectionModalType
}

export enum AuthModalType {
  SignIn,
  SignUp,
  ForgotPassword,
}

export enum SignUpStep {
  Credentials,
  UserDetails,
}

export type LogInFormState = {
  email: string
  password: string
}

export type ForgotPasswordFormState = {
  email: string
}

export type SignUpFormState = {
  agreedToTerms: boolean
  currentStep: SignUpStep
  email: string
  firstName: string
  lastName: string
  password: string
  phoneNumber: string
}

export type UserRegistrationData = {
  email: string
  password: string
  firstName: string
  lastName: string
  telephoneNumber: string
  languageCode: string | null
}

export type SignInWithActionPayload = {
  signInProvider: SignInProvider
  credential?: {
    email: string
    password: string
  }
}

export type UserUpdateData = {
  firstName: string
  lastName: string
  telephoneNumber: string
  languageCode: string | null
}

export enum AuthMode {
  VerifyEmail = 'verifyEmail',
  InvitationSignUp = 'invitationSignup',
}

export enum AuthVerificationStatusType {
  Pending,
  Success,
  Failed,
}

export type AuthPageQuery = {
  token: string
  oobCode?: string
  lang?: string
  authMode?: AuthMode
}

export type CompanyInvitationSignUpFormState = {
  email: string
  firstName: string
  lastName: string
  password: string
  phoneNumber: string
  department: string
}

export enum AuthProfileType {
  Customer = 'customer',
  Company = 'company',
}

export type CompanySignUpFormState = {
  companyName: string
  businessNumber: string
  companyPhoneNumber: string
  country: string
  outlet: OutletSearchListItem | null
  // Invoicing address
  street: string
  zipCode: string
  city: string
  invoiceEmail: string
  // Company member details
  firstName: string
  lastName: string
  email: string
  password: string
  telephoneNumber: string
  department: string
}
