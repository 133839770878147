import { createSelector } from '@reduxjs/toolkit'
import { SignInProvider } from '@ancon/wildcat-types'

import { RootState } from '../../../store/types'
import { AuthProfileType, AuthReducerState } from '../types'
import {
  clientContextIsAnonymousCustomerSelector,
  clientContextIsCompanyUserSelector,
} from '../../clientContext/store/clientContextSelectors'

function authSelector<K extends keyof AuthReducerState>(
  state: RootState,
  key: K,
) {
  return state.auth[key]
}

// Simple selectors
export const authSignInProviderSelector = (state: RootState) =>
  authSelector(state, 'signInProvider')

export const authSignInPendingSelector = (state: RootState) =>
  authSelector(state, 'signInPending')

export const authSignUpPendingSelector = (state: RootState) =>
  authSelector(state, 'signUpPending')

export const authSocialSignUpPendingSelector = (state: RootState) =>
  authSelector(state, 'socialSignUpPending')

export const authSignOutPendingSelector = (state: RootState) =>
  authSelector(state, 'signOutPending')

export const authInitializedSelector = (state: RootState) =>
  authSelector(state, 'clientSDKLoaded')

export const authModalVisibleSelector = (state: RootState) =>
  authSelector(state, 'showAuthModal')

export const authModalTypeSelector = (state: RootState) =>
  authSelector(state, 'authModalType')

export const authCustomerIsLoggedInSelector = (state: RootState) =>
  authSelector(state, 'loggedIn')

export const authProfileSelectionModalTypeSelector = (state: RootState) =>
  authSelector(state, 'profileSelectionModalType')

export const authCompanySignUpModalVisibleSelector = (state: RootState) =>
  authSelector(state, 'showCompanySignUpModal')

export const authAccountEditModalVisibleSelector = (state: RootState) =>
  authSelector(state, 'showEditAccountModal')

export const authGuestCustomerOnboardingModalVisibleSelector = (
  state: RootState,
) => authSelector(state, 'showGuestCustomerOnboardingModal')

export const authEmailPasswordSignInPendingSelector = createSelector(
  [authSignInPendingSelector, authSignInProviderSelector],
  (signInPending, signInProvider) =>
    signInPending && signInProvider === SignInProvider.EmailPassword,
)

export const authSignUpPendingBySignInProviderSelector = createSelector(
  [
    authSignUpPendingSelector,
    authSignInProviderSelector,
    (state, signInProvider: SignInProvider) => signInProvider,
  ],
  (signUpPending, currentSignInProvider, signInProvider) =>
    signUpPending && currentSignInProvider === signInProvider,
)

export const authCurrentUserProfileTypeSelector = createSelector(
  [authCustomerIsLoggedInSelector, clientContextIsCompanyUserSelector],
  (isLoggedIn, isCompanyUser) => {
    if (isLoggedIn) {
      return isCompanyUser ? AuthProfileType.Company : AuthProfileType.Customer
    }
    return undefined
  },
)

export const authIsLoggedInCustomerSelector = createSelector(
  [clientContextIsAnonymousCustomerSelector, authCustomerIsLoggedInSelector],
  (isAnonymous, isLoggedIn) => !isAnonymous && isLoggedIn,
)
