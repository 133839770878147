import React from 'react'
import clsx from 'clsx'
import {
  CheckoutDetails,
  CheckoutSummary,
  OrderFormat,
} from '@ancon/wildcat-types'
import getCheckoutGrandTotalAmount from '@ancon/wildcat-utils/checkout/getCheckoutGrandTotalAmount'
import useTranslation from 'next-translate/useTranslation'

import OrderAmountField from './OrderAmountField'
import OrderGrandTotal from './OrderGrandTotal'
import styles from './OrderTotal.module.scss'

type OrderTotalProps = {
  separateTotal?: boolean
  checkout: CheckoutSummary | CheckoutDetails
  className?: string
}

export default function OrderTotal({
  separateTotal = false,
  checkout,
  className,
}: OrderTotalProps) {
  const { t } = useTranslation('common')

  const {
    subtotalInclTax,
    minimumOrderDifferenceAmount,
    feeAmountInclTax,
    discount,
    subTotalDiscountInclTax,
    orderFormat,
  } = checkout

  const isDeliveryOrder = orderFormat === OrderFormat.Delivery

  const checkoutTotal = getCheckoutGrandTotalAmount(checkout as CheckoutDetails)

  const showDiscount = discount && subTotalDiscountInclTax
  const showMinOrderDifference =
    minimumOrderDifferenceAmount && minimumOrderDifferenceAmount.amount > 0
  const showDeliveryFee = isDeliveryOrder && feeAmountInclTax

  return (
    <div className={clsx(styles.container, className)}>
      {showDiscount && (
        <OrderAmountField
          name={t('components.order.discount', { name: discount.name })}
          amount={subTotalDiscountInclTax}
          showAsDeduction
        />
      )}
      {subtotalInclTax && (
        <OrderAmountField
          name={t('components.order.subTotal')}
          amount={subtotalInclTax}
        />
      )}
      {showMinOrderDifference && (
        <OrderAmountField
          name={t('components.order.difference')}
          amount={minimumOrderDifferenceAmount}
        />
      )}
      {showDeliveryFee && (
        <OrderAmountField
          name={t('components.order.delivery')}
          amount={feeAmountInclTax}
        />
      )}
      <OrderGrandTotal total={checkoutTotal} showSeparator={separateTotal} />
    </div>
  )
}
