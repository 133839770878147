/* eslint-disable no-param-reassign */
import { PayloadAction } from '@reduxjs/toolkit'
import { OutletDetails } from '@ancon/wildcat-types'

import {
  OrderConfigureFilterMode,
  OutletFilters,
  OutletReducerState,
} from '../types'
import getCheckoutFilters from '../../checkout/utils/getCheckoutFilters'
import { CheckoutFilters } from '../../checkout/types'

export const outletReducers = {
  outletSetOrderConfigureFilterModal: (
    state: OutletReducerState,
    action: PayloadAction<OrderConfigureFilterMode>,
  ) => {
    state.orderConfigureFilterModal = action.payload
  },
  outletSetIsVisibleDeliveryLocationModal: (
    state: OutletReducerState,
    action: PayloadAction<boolean>,
  ) => {
    state.isVisibleDeliveryLocationModal = action.payload
  },
  outletSetOutletFilters: (
    state: OutletReducerState,
    action: PayloadAction<Partial<OutletFilters>>,
  ) => {
    state.outletFilters = getCheckoutFilters(
      state.outletFilters as CheckoutFilters,
      action.payload as Partial<CheckoutFilters>,
    )
  },
  outletSetIsVisibleOutletChangeWarningModal(
    state: OutletReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isVisibleOutletChangeWarningModal = action.payload
  },
  outletSetIsVisibleTableOrderConfigureModal(
    state: OutletReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isVisibleTableOrderConfigureModal = action.payload
  },
  outletClearTableNumberValidateError(state: OutletReducerState) {
    state.outletTableNumberValidateError = null
  },
  outletSetIsVisibleOutletDetailsModal: (
    state: OutletReducerState,
    action: PayloadAction<boolean>,
  ) => {
    state.isVisibleOutletDetailsModal = action.payload
  },
  outletSetOutletDetails: (
    state: OutletReducerState,
    action: PayloadAction<OutletDetails | null>,
  ) => {
    state.outletDetails = action.payload
  },
  outletSetIsVisibleServiceTimeKindSelectionModal: (
    state: OutletReducerState,
    action: PayloadAction<boolean>,
  ) => {
    state.isVisibleServiceTimeKindSelectionModal = action.payload
  },
  outletSetIsVisibleBoxPickupOrderConfigureModal: (
    state: OutletReducerState,
    action: PayloadAction<boolean>,
  ) => {
    state.isVisibleBoxPickupOrderConfigureModal = action.payload
  },
}
