import { useCallback } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { FormProvider, useForm } from 'react-hook-form'
import noop from 'lodash/noop'

import { CompanySignUpFormState, ProfileSelectionModalType } from '../../types'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import {
  clientContextCustomerDetailsSelector,
  clientContextHasCompanyProfilesSelector,
} from '../../../clientContext/store/clientContextSelectors'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import { registerAsCompanyUser } from '../../store/authThunks'
import {
  setAuthCompanySignUpModalVisible,
  setAuthProfileSelectionModalType,
} from '../../store/authSlice'
import Button from '../../../app/components/Button'
import useAppStore from '../../../../store/hooks/useAppStore'

import CompanyDetails from './CompanyDetails'
import CompanyInvoicingDetails from './CompanyInvoicingDetails'
import CompanyMemberDetails from './CompanyMemberDetails'
import styles from './CompanySignUpForm.module.scss'

export default function CompanySignUpForm() {
  const { t } = useTranslation('common')

  const dispatch = useAppDispatch()

  const store = useAppStore()

  const customer = useAppSelector(clientContextCustomerDetailsSelector)

  const form = useForm<CompanySignUpFormState>({
    mode: 'onChange',
    defaultValues: {
      companyName: '',
      businessNumber: '',
      companyPhoneNumber: '',
      outlet: null,
      country: '',
      street: '',
      zipCode: '',
      city: '',
      invoiceEmail: '',
      firstName: customer?.firstName || '',
      lastName: customer?.lastName || '',
      email: customer?.email || '',
      telephoneNumber: customer?.telephoneNumber || '',
      password: '',
      department: '',
    },
  })

  const {
    formState: { isSubmitting, isValid, isDirty },
    handleSubmit,
  } = form

  const handleSignUpSuccess = useCallback(() => {
    dispatch(setAuthCompanySignUpModalVisible(false))

    const hasCompanyProfiles = clientContextHasCompanyProfilesSelector(
      store.getState(),
    )

    if (hasCompanyProfiles) {
      dispatch(
        setAuthProfileSelectionModalType(
          ProfileSelectionModalType.ProfileSelection,
        ),
      )
    }
  }, [dispatch, store])

  async function handleSignUp(values: CompanySignUpFormState) {
    await dispatch(registerAsCompanyUser(values))
      .unwrap()
      .then(handleSignUpSuccess)
      .catch(noop)
  }

  return (
    <FormProvider
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...form}
    >
      <div className={styles.formBody}>
        <CompanyDetails />
        <CompanyMemberDetails />
        <CompanyInvoicingDetails />
      </div>
      <div className={styles.formFooter}>
        <Button
          onClick={handleSubmit(handleSignUp)}
          loading={isSubmitting}
          disabled={!isValid || !isDirty || isSubmitting}
          size="large"
        >
          {t('companySignUpModal.signUpAsCompany')}
        </Button>
      </div>
    </FormProvider>
  )
}
