import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { OrderReducerState } from '../types'
import { signOut } from '../../auth/store/authThunks'

import {
  downloadOrderReceipt,
  fetchOngoingOrders,
  fetchOrderDetails,
  fetchOrdersList,
  fetchOrderSummary,
  fetchUpcomingOrders,
  sendOrderReceipt,
} from './ordersThunks'
import {
  activeOrdersEntityAdapter,
  pastOrdersEntityAdapter,
  upcomingOrdersEntityAdapter,
} from './ordersEntityAdapter'
import ordersReducers from './ordersReducer'

const ordersInitialState: OrderReducerState = {
  activeOrders: activeOrdersEntityAdapter.getInitialState(),
  activeOrderFetchPending: true,

  pastOrders: pastOrdersEntityAdapter.getInitialState(),
  pastOrdersFetchPending: false,

  upcomingOrders: upcomingOrdersEntityAdapter.getInitialState(),
  upcomingOrdersFetchPending: false,

  orderDetailsFetchPending: false,
  orderDetails: null,

  orderSummary: null,
  orderSummaryFetchPending: false,

  orderReceiptSendPending: false,
  isReceiptDownloadPending: false,
}

const ordersSlice = createSlice({
  name: 'orders',
  initialState: ordersInitialState,
  reducers: ordersReducers,
  extraReducers(builder) {
    // Download receipt
    builder.addCase(downloadOrderReceipt.pending, state => {
      state.isReceiptDownloadPending = true
    })

    // Fetch Active Orders
    builder.addCase(fetchOngoingOrders.pending, state => {
      state.activeOrderFetchPending = true
    })
    builder.addCase(fetchOngoingOrders.fulfilled, (state, action) => {
      const { payload, meta } = action
      const { offset } = meta.arg

      if (!offset) {
        activeOrdersEntityAdapter.setAll(state.activeOrders, payload.items)
      } else {
        activeOrdersEntityAdapter.addMany(state.activeOrders, payload.items)
      }
      state.activeOrderFetchPending = false
    })
    builder.addCase(fetchOngoingOrders.rejected, state => {
      state.activeOrderFetchPending = false
    })

    // Fetch Past Orders
    builder.addCase(fetchOrdersList.pending, state => {
      state.pastOrdersFetchPending = true
    })
    builder.addCase(fetchOrdersList.fulfilled, (state, action) => {
      const { payload, meta } = action
      const { offset } = meta.arg

      if (!offset) {
        pastOrdersEntityAdapter.setAll(state.pastOrders, payload.items)
      } else {
        pastOrdersEntityAdapter.addMany(state.pastOrders, payload.items)
      }
      state.pastOrdersFetchPending = false
    })
    builder.addCase(fetchOrdersList.rejected, state => {
      state.pastOrdersFetchPending = false
    })

    // Fetch Upcoming Orders
    builder.addCase(fetchUpcomingOrders.pending, state => {
      state.upcomingOrdersFetchPending = true
    })
    builder.addCase(fetchUpcomingOrders.fulfilled, (state, action) => {
      const { payload, meta } = action
      const { offset } = meta.arg

      if (!offset) {
        upcomingOrdersEntityAdapter.setAll(state.upcomingOrders, payload.items)
      } else {
        upcomingOrdersEntityAdapter.addMany(state.upcomingOrders, payload.items)
      }
      state.upcomingOrdersFetchPending = false
    })
    builder.addCase(fetchUpcomingOrders.rejected, state => {
      state.upcomingOrdersFetchPending = false
    })

    // Fetch order details
    builder.addCase(fetchOrderDetails.pending, state => {
      state.orderDetailsFetchPending = true
      state.orderDetails = null
    })
    builder.addCase(fetchOrderDetails.fulfilled, (state, action) => {
      const { payload } = action

      state.orderDetailsFetchPending = false
      state.orderDetails = payload
    })
    builder.addCase(fetchOrderDetails.rejected, state => {
      state.orderDetailsFetchPending = false
    })

    // On send order receipt start
    builder.addCase(sendOrderReceipt.pending, state => {
      state.orderReceiptSendPending = true
    })

    // Sign out
    builder.addCase(signOut.fulfilled, () => ordersInitialState)

    // On fetch order summary pending
    builder.addCase(fetchOrderSummary.pending, state => {
      state.orderSummaryFetchPending = true
      state.orderSummary = null
    })
    // On fetch order summary finish
    builder.addCase(fetchOrderSummary.fulfilled, (state, action) => {
      const { payload } = action

      state.orderSummaryFetchPending = false
      state.orderSummary = payload
    })
    // On fetch order summary error
    builder.addCase(fetchOrderSummary.rejected, state => {
      state.orderSummaryFetchPending = false
    })

    // On download order receipt finish
    builder.addMatcher(
      isAnyOf(downloadOrderReceipt.fulfilled, downloadOrderReceipt.rejected),
      state => {
        state.isReceiptDownloadPending = false
      },
    )
    // On send order receipt finish
    builder.addMatcher(
      isAnyOf(sendOrderReceipt.fulfilled, sendOrderReceipt.rejected),
      state => {
        state.orderReceiptSendPending = false
      },
    )
  },
})

export const {
  ordersClearOrderDetails,
  ordersUpdateOrderSummaryTicketStatus,
  ordersHandleCheckoutStatusSignal,
  ordersHandleCheckoutItemStatusSignal,
} = ordersSlice.actions

export default ordersSlice.reducer
