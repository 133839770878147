import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { ClientContextResponseV2 } from '@ancon/wildcat-types'

import { AuthReducerState, ProfileSelectionModalType } from '../types'
import { fetchClientContext } from '../../clientContext/store/clientContextThunks'

import {
  registerSocialAuthCustomer,
  registerWithUserDetails,
  signInWith,
  signOut,
} from './authThunks'
import authReducers from './authReducers'

const authInitialState: AuthReducerState = {
  clientSDKLoaded: false,

  loggedIn: null,

  signInPending: false,
  signInProvider: null,

  signUpPending: false,

  socialSignUpPending: false,

  showAuthModal: false,
  isSignUpModal: false,
  showCompanySignUpModal: false,
  showEditAccountModal: false,
  showGuestCustomerOnboardingModal: false,

  signOutPending: false,

  profileSelectionModalType: ProfileSelectionModalType.None,
}

const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: authReducers,
  extraReducers: builder => {
    // Sign Up
    builder.addCase(registerWithUserDetails.pending, state => {
      state.signUpPending = true
    })

    // Email-Password, Google, Facebook & Apple Sign In
    builder.addCase(signInWith.pending, (state, action) => {
      state.signInPending = true
      state.signInProvider = action.meta.arg.signInProvider
    })

    // Google, Facebook & Apple Sign Up
    builder.addCase(registerSocialAuthCustomer.pending, state => {
      state.socialSignUpPending = true
    })

    // Sign Out
    builder.addCase(signOut.pending, state => {
      state.signOutPending = true
    })
    builder.addCase(signOut.fulfilled, state => ({
      ...authInitialState,
      loggedIn: false,
      clientSDKLoaded: state.clientSDKLoaded,
    }))
    builder.addCase(signOut.rejected, state => {
      state.signOutPending = false
    })

    // Client Context
    builder.addCase(
      fetchClientContext.fulfilled,
      (state, action: PayloadAction<ClientContextResponseV2 | null>) => {
        state.loggedIn = !!action.payload?.customer?.customerUserId
      },
    )
    builder.addCase(fetchClientContext.rejected, state => {
      state.loggedIn = false
    })

    // Email-Password Sign Up
    builder.addMatcher(
      isAnyOf(
        registerWithUserDetails.fulfilled,
        registerWithUserDetails.rejected,
      ),
      state => {
        state.signUpPending = false
      },
    )

    // Google, Facebook & Apple Sign Up
    builder.addMatcher(
      isAnyOf(
        registerSocialAuthCustomer.fulfilled,
        registerSocialAuthCustomer.rejected,
      ),
      state => {
        state.socialSignUpPending = false
      },
    )

    // Email-Password, Google, Facebook & Apple Sign In
    builder.addMatcher(
      isAnyOf(signInWith.fulfilled, signInWith.rejected),
      state => {
        state.signInPending = false
        state.signInProvider = null
      },
    )
  },
})

export const {
  showAuthModal,
  hideAuthModal,
  setAuthClientSDKInitialized,
  setAuthProfileSelectionModalType,
  setAuthCompanySignUpModalVisible,
  setAuthAccountEditModalVisible,
  setAuthGuestCustomerOnboardingModalVisible,
} = authSlice.actions

export default authSlice.reducer
