import { UseControllerProps, useController } from 'react-hook-form'
import clsx from 'clsx'
import {
  DropdownAsyncOption,
  DropdownAsyncProps,
} from '@ancon/wildcat-ui/web/orderweb/DropdownAsync'
import useTranslation from 'next-translate/useTranslation'

import BodyText from './BodyText'
import DropdownAsync from './DropdownAsync'
import FormInputErrorLabel from './FormInputErrorLabel'
import styles from './FormDropdownAsync.module.scss'

type ReactHookFormRules = UseControllerProps['rules']

interface FormDropdownAsyncProps extends DropdownAsyncProps {
  /* Name of the field in form state */
  fieldName: string
  /* Text label of the input */
  label?: string
  /* Validation rules of the input */
  rules?: ReactHookFormRules
  /* Class name of the container */
  containerClassName?: string
  /* Class name for the label */
  labelClassName?: string
  /* Whether input is required */
  required?: boolean
}

export default function FormDropdownAsync({
  fieldName,
  label,
  rules,
  containerClassName,
  labelClassName,
  required,
  ...dropdownAsyncProps
}: FormDropdownAsyncProps) {
  const { t } = useTranslation('common')

  const {
    field: { onChange, onBlur },
    fieldState: { error, isTouched },
  } = useController({
    name: fieldName,
    rules,
  })

  const hasError = !!error && isTouched

  function handleChangeOption(option: DropdownAsyncOption) {
    onChange(option)
  }

  return (
    <div className={clsx(styles.container, containerClassName)}>
      {label && (
        <BodyText
          as="label"
          color="body-1"
          className={clsx(styles.label, labelClassName)}
        >
          {label}
          {required && <span>{' *'}</span>}
        </BodyText>
      )}
      <DropdownAsync
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...dropdownAsyncProps}
        className={clsx(!hasError && styles.valid)}
        onChangeOption={handleChangeOption}
        onBlur={onBlur}
        invalid={hasError}
        translateFn={t}
      />
      {hasError ? (
        <FormInputErrorLabel htmlFor={fieldName} error={error?.message} />
      ) : (
        <div className={styles.spacer} />
      )}
    </div>
  )
}
