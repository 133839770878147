import React, { useMemo } from 'react'
import clsx from 'clsx'
import { OrderFormat, PreOrderCheckoutDetails } from '@ancon/wildcat-types'
import getCheckoutGrandTotalAmount from '@ancon/wildcat-utils/checkout/getCheckoutGrandTotalAmount'
import useTranslation from 'next-translate/useTranslation'
import getTotalCheckoutItemsQuantity from '@ancon/wildcat-utils/checkout/getTotalCheckoutItemsQuantity'

import OrderAmountField from '../../checkout/components/orderTotal/OrderAmountField'
import OrderGrandTotal from '../../checkout/components/orderTotal/OrderGrandTotal'
import calculateAttendeeItemsTotalAmount from '../utils/calculateAttendeeItemsTotalAmount'
import { preOrderUserIsHostSelector } from '../store/preOrderSelectors'
import useAppSelector from '../../../store/hooks/useAppSelector'

import styles from './PreOrderTotal.module.scss'

type PreOrderTotalProps = {
  checkout: PreOrderCheckoutDetails
  /** To show the total for a specific attendee (host or attendee) */
  isAttendeeTotal?: boolean
  separateTotal?: boolean
  showTotalItemsQty?: boolean
  className?: string
}

export default function PreOrderTotal({
  checkout,
  isAttendeeTotal = false,
  separateTotal = false,
  showTotalItemsQty = false,
  className,
}: PreOrderTotalProps) {
  const { t } = useTranslation('common')

  const {
    subtotalInclTax,
    minimumOrderDifferenceAmount,
    feeAmountInclTax,
    discount,
    subTotalDiscountInclTax,
    orderFormat,
    attendee,
    attendees,
  } = checkout

  const isHost = useAppSelector(preOrderUserIsHostSelector)

  const isDeliveryOrder = orderFormat === OrderFormat.Delivery

  const showDiscount = !isAttendeeTotal && discount && subTotalDiscountInclTax
  const showSubtotal = !isAttendeeTotal && subtotalInclTax
  const showMinOrderDifference =
    !isAttendeeTotal &&
    minimumOrderDifferenceAmount &&
    minimumOrderDifferenceAmount.amount > 0
  const showDeliveryFee =
    !isAttendeeTotal && isDeliveryOrder && feeAmountInclTax

  const attendeeItems = useMemo(() => {
    if (isAttendeeTotal) {
      if (isHost) {
        return attendees?.find(a => a.isHost)?.items ?? []
      }

      return attendee?.items ?? []
    }

    return []
  }, [attendee?.items, attendees, isAttendeeTotal, isHost])

  const checkoutTotal = useMemo(() => {
    if (isAttendeeTotal) {
      return calculateAttendeeItemsTotalAmount(attendeeItems)
    }

    return getCheckoutGrandTotalAmount(checkout)
  }, [attendeeItems, checkout, isAttendeeTotal])

  const totalItemsQty = useMemo(() => {
    if (showTotalItemsQty) {
      const checkoutItems = isAttendeeTotal ? attendeeItems : checkout.items

      return getTotalCheckoutItemsQuantity(checkoutItems ?? [])
    }

    return undefined
  }, [attendeeItems, checkout.items, isAttendeeTotal, showTotalItemsQty])

  return (
    <div className={clsx(styles.container, className)}>
      {showDiscount && (
        <OrderAmountField
          name={t('components.order.discount', { name: discount.name })}
          amount={subTotalDiscountInclTax}
          showAsDeduction
        />
      )}
      {showSubtotal && (
        <OrderAmountField
          name={t('components.order.subTotal')}
          amount={subtotalInclTax}
        />
      )}
      {showMinOrderDifference && (
        <OrderAmountField
          name={t('components.order.difference')}
          amount={minimumOrderDifferenceAmount}
        />
      )}
      {showDeliveryFee && (
        <OrderAmountField
          name={t('components.order.delivery')}
          amount={feeAmountInclTax}
        />
      )}
      {checkoutTotal && (
        <OrderGrandTotal
          total={checkoutTotal}
          showSeparator={separateTotal}
          totalItemsQty={totalItemsQty}
        />
      )}
    </div>
  )
}
