import { Draft, PayloadAction } from '@reduxjs/toolkit'
import {
  AttendeePreOrderStatus,
  CheckoutItem,
  CheckoutItemStatusUpdatedEvent,
  CheckoutStatus,
  CheckoutStatusUpdatedEvent,
  CustomerPreOrderCheckout,
} from '@ancon/wildcat-types'

import {
  NewOrderDetails,
  NewOrderStep,
  PreOrderCheckoutEntityType,
  PreOrderMethod,
  PreOrderReducerState,
  PreOrderStartStep,
  PreOrderUser,
} from '../types'

import preOrderInitialState from './preOrderInitialState'

const preOrderReducers = {
  setPreOrderStartStep(
    state: PreOrderReducerState,
    action: PayloadAction<PreOrderStartStep>,
  ) {
    state.startStep = action.payload
  },
  setPreOrderInfoModalVisible(
    state: PreOrderReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.showPreOrderInfoModal = action.payload
  },
  setMobilePreOrderMembersManageStep(
    state: PreOrderReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isMobilePreOrderMembersManageStep = action.payload
  },
  setSelectedPreOrderAttendeeId(
    state: PreOrderReducerState,
    action: PayloadAction<string | null>,
  ) {
    state.preOrderSelectedAttendeeId = action.payload
  },
  setPreOrderAttendeeRemoveModalVisible(
    state: PreOrderReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isPreOrderAttendeeRemoveModalVisible = action.payload
  },
  setPreOrderUser(
    state: PreOrderReducerState,
    action: PayloadAction<PreOrderUser | null>,
  ) {
    state.preOrderUser = action.payload
  },
  setPreOrderAttendeeConfirmPendingModalVisible(
    state: PreOrderReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isAttendeeConfirmPendingModalVisible = action.payload
  },
  setPreOrderAttendanceConfirmationModalVisible(
    state: PreOrderReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isAttendanceConfirmationModalVisible = action.payload
  },
  setPreOrderAttendeeEmptyItemsWarningModalVisible(
    state: PreOrderReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isAttendeeEmptyItemsWarningModalVisible = action.payload
  },
  preOrderClearCheckout(state: PreOrderReducerState) {
    state.preOrderCheckout = null
    state.preOrderLink = null
  },
  preOrderClearCheckoutSummary(state: PreOrderReducerState) {
    state.preOrderCheckoutSummary = null
    state.preOrderCheckoutSummaryFetchPending = false
    state.preOrderSummaryOutletListItem = null
    state.preOrderCheckoutStatusLastUpdatedAt = 0
  },
  preOrderCheckoutEmitStatusUpdatedEvent(
    state: PreOrderReducerState,
    action: PayloadAction<CheckoutStatusUpdatedEvent>,
  ) {
    function handleCheckoutStatusEvent(key: PreOrderCheckoutEntityType) {
      const {
        checkoutId,
        status,
        timestamp,
        cancelInfo,
        preparationTime,
        ticketId,
      } = action.payload

      if (state[key] && checkoutId === state[key]!.id) {
        let timestampDateValue = new Date(timestamp).valueOf()

        if (Number.isNaN(timestampDateValue)) {
          timestampDateValue = 0
        }

        if (
          !Number.isNaN(status) &&
          timestampDateValue >= state.preOrderCheckoutStatusLastUpdatedAt
        ) {
          state[key]!.checkoutStatus = status
          state.preOrderCheckoutStatusLastUpdatedAt = timestampDateValue

          if (status === CheckoutStatus.InProgress) {
            // Update service time - otherwise there can be difference with POS and AOW
            state[key]!.serviceTime.time = timestamp
          }
        }

        if (cancelInfo) {
          state[key]!.cancelInfo = cancelInfo
        }

        if (preparationTime) {
          state[key]!.tickets[0] = {
            ...state[key]!.tickets[0],
            preparationTime,
          }
        }

        if (ticketId) {
          state[key]!.tickets[0] = { ...state[key]!.tickets[0], id: ticketId }
        }
      }
    }

    handleCheckoutStatusEvent('preOrderCheckout')
    handleCheckoutStatusEvent('preOrderCheckoutSummary')
  },
  preOrderCheckoutEmitItemStatusUpdatedEvent(
    state: PreOrderReducerState,
    action: PayloadAction<CheckoutItemStatusUpdatedEvent>,
  ) {
    const { checkoutItemId, ticketItemId, ticketItemStatus } = action.payload

    function updateTicketItemStatus(items?: Draft<CheckoutItem[]>) {
      items?.forEach(item => {
        if (item.id === checkoutItemId) {
          item.ticketItem = {
            ...item.ticketItem,
            ticketItemId,
            status: ticketItemStatus,
          }
        }
      })
    }

    if (
      state.preOrderCheckoutSummary &&
      action.payload.checkoutId === state.preOrderCheckoutSummary.id
    ) {
      updateTicketItemStatus(state.preOrderCheckoutSummary.items)
      updateTicketItemStatus(state.preOrderCheckoutSummary.attendee?.items)
      state.preOrderCheckoutSummary.attendees?.forEach(attendee => {
        updateTicketItemStatus(attendee.items)
      })
    }
  },
  preOrderUpdateMembersStatus(
    state: PreOrderReducerState,
    action: PayloadAction<{
      memberIds: string[]
      status: AttendeePreOrderStatus
    }>,
  ) {
    const { memberIds, status } = action.payload

    if (state.preOrderCheckout) {
      state.preOrderCheckout.attendees?.forEach(attendee => {
        if (memberIds.includes(attendee.id)) {
          attendee.status = status
        }
      })
    }
  },
  setOrderCalenderSelectedDate(
    state: PreOrderReducerState,
    action: PayloadAction<string>,
  ) {
    state.orderCalendarSelectedDate = action.payload
  },
  setOrderCalendarSelectedMonth(
    state: PreOrderReducerState,
    action: PayloadAction<string>,
  ) {
    state.orderCalendarSelectedMonth = action.payload
  },
  preOrderGoNextNewOrderStep(
    state: PreOrderReducerState,
    action: PayloadAction<
      | {
          step: NewOrderStep
          isMobile: boolean
        }
      | NewOrderStep
    >,
  ) {
    let nextStep = NewOrderStep.None
    let isMobile = false

    if (typeof action.payload === 'object') {
      nextStep = action.payload.step
      isMobile = action.payload.isMobile
    } else {
      nextStep = action.payload
    }

    state.newOrderStep = nextStep
    if (nextStep === NewOrderStep.Summary) {
      state.newOrderEditModeStep = NewOrderStep.None
    }
    if (isMobile) {
      state.isNewOrderModalVisible =
        nextStep === NewOrderStep.OrderConfiguration ||
        nextStep === NewOrderStep.OrderMethodSelection
    }
  },
  preOrderGoBackNewOrderStep(
    state: PreOrderReducerState,
    action: PayloadAction<
      | {
          isMobile?: boolean
        }
      | undefined
    >,
  ) {
    const isIndividualOrder =
      state.newOrder.orderMethod === PreOrderMethod.Individual
    const isInSummary = state.newOrderStep === NewOrderStep.Summary
    const isEditMode = state.newOrderEditModeStep > NewOrderStep.None

    if (isEditMode) {
      state.newOrderStep = NewOrderStep.Summary
      state.newOrderEditModeStep = NewOrderStep.None
    } else if (isIndividualOrder && isInSummary) {
      // Skip the host preview step
      state.newOrderStep = NewOrderStep.OrderMethodSelection
    } else {
      state.newOrderStep = Math.max(
        state.newOrderStep - 1,
        NewOrderStep.OrderConfiguration,
      )
    }

    if (action.payload?.isMobile) {
      state.isNewOrderModalVisible =
        state.newOrderStep === NewOrderStep.OrderConfiguration ||
        state.newOrderStep === NewOrderStep.OrderMethodSelection
    }
  },
  setNewOrderDetails(
    state: PreOrderReducerState,
    action: PayloadAction<Partial<NewOrderDetails>>,
  ) {
    state.newOrder = {
      ...state.newOrder,
      ...action.payload,
    }
  },
  resetNewOrderDetails(state: PreOrderReducerState) {
    state.newOrder = preOrderInitialState.newOrder
    // If there is a created group order ID, reset it as well
    state.createdGroupOrderId = null
  },
  preOrderSetSelectedPreOrder(
    state: PreOrderReducerState,
    action: PayloadAction<CustomerPreOrderCheckout | null>,
  ) {
    if (action.payload) {
      const selectedPreOrderListItem = action.payload
      state.selectedPreOrderId = selectedPreOrderListItem.id
      state.selectedPreOrderListItem = selectedPreOrderListItem
    } else {
      state.selectedPreOrderId = null
      state.selectedPreOrderListItem = null
      // Reset selected pre-order details
      state.selectedPreOrderDetails = null
    }
  },
  setNewPreOrderModalVisible(
    state: PreOrderReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isNewOrderModalVisible = action.payload
  },
  setNewPreOrderEditModeStep(
    state: PreOrderReducerState,
    action: PayloadAction<NewOrderStep>,
  ) {
    state.newOrderEditModeStep = action.payload
  },
  setPreOrderLeaveModalVisible(
    state: PreOrderReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isPreOrderLeaveModalVisible = action.payload
  },
  setPreOrderExistWarningModalVisible(
    state: PreOrderReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isPreOrderExistWarningModalVisible = action.payload
  },
  resetPreOrderState() {
    return preOrderInitialState
  },
}

export default preOrderReducers
