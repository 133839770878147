import React from 'react'
import CloseIcon from '@ancon/wildcat-ui/shared/icons/close.svg'
import OrderTypeIcon from '@ancon/wildcat-ui/web/orderweb/OrderTypeIcon'
import { OrderFormat } from '@ancon/wildcat-types'

import HeadingText from '../../../app/components/HeadingText'
import BodyText from '../../../app/components/BodyText'
import Button from '../../../app/components/Button'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import { appSetIsCartDrawerVisible } from '../../../app/store/appSlice'
import Spinner from '../../../app/components/Spinner'
import OrderFormatText from '../../../app/components/OrderFormatText'

import styles from './CartHeader.module.scss'

interface CartHeaderProps {
  title: string
  outletName: string
  orderFormat?: OrderFormat
  loading?: boolean
  additionalActions?: React.ReactNode
}

export default function CartHeader({
  title,
  outletName,
  orderFormat,
  loading = false,
  additionalActions,
}: CartHeaderProps) {
  const dispatch = useAppDispatch()

  function handleDrawerCloseClick() {
    dispatch(appSetIsCartDrawerVisible(false))
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <div className={styles.headerTitle}>
            <HeadingText as="h2">{title}</HeadingText>
            {loading && <Spinner size="medium" />}
          </div>
          <BodyText color="body-2">{outletName}</BodyText>
          {orderFormat && (
            <div className={styles.orderFormat}>
              <OrderTypeIcon orderFormat={orderFormat} />
              <OrderFormatText
                as="span"
                className={styles.orderFormatText}
                orderFormat={orderFormat}
              />
            </div>
          )}
        </div>
        <div className={styles.headerActions}>
          {additionalActions}
          <Button
            className={styles.headerCloseButton}
            variant="secondary"
            onClick={handleDrawerCloseClick}
          >
            <CloseIcon />
          </Button>
        </div>
      </div>
    </div>
  )
}
