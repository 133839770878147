import { HTMLAttributes } from 'react'
import useTranslation from 'next-translate/useTranslation'
import clsx from 'clsx'

import BodyText from '../../../app/components/BodyText'

import styles from './Separator.module.scss'

export default function Separator({
  className,
}: HTMLAttributes<HTMLDivElement>) {
  const { t } = useTranslation('common')

  return (
    <div className={clsx(styles.separator, className)}>
      <div className={styles.line} />
      <BodyText className={styles.text} as="span" fontSize="1.6rem">
        {t('or')}
      </BodyText>
      <div className={styles.line} />
    </div>
  )
}
