import UserProfileIcon from '@ancon/wildcat-ui/shared/icons/circle-user-outline.svg'
import CompanyIcon from '@ancon/wildcat-ui/shared/icons/company-outline.svg'
import clsx from 'clsx'

import { AuthProfileType } from '../types'

import styles from './AuthProfileTypeIcon.module.scss'

function getAuthProfileIcon(profileType: AuthProfileType) {
  switch (profileType) {
    case AuthProfileType.Company:
      return CompanyIcon

    case AuthProfileType.Customer:
    default:
      return UserProfileIcon
  }
}

type AuthProfileIconProps = {
  type?: AuthProfileType
  className?: string
}

export default function AuthProfileTypeIcon({
  type = AuthProfileType.Customer,
  className,
}: AuthProfileIconProps) {
  const ProfileIconComponent = getAuthProfileIcon(type)

  return (
    <div className={clsx(styles.iconContainer, className)}>
      <ProfileIconComponent />
    </div>
  )
}
