import React, { Fragment } from 'react'

import BodyText from '../../../app/components/BodyText'

import styles from './CartFooter.module.scss'

interface CartFooterProps {
  totalItemsQty: number
  cartTotal?: React.ReactNode
  footerButtons: Array<React.ReactNode>
}

export default function CartFooter({
  totalItemsQty,
  cartTotal,
  footerButtons,
}: CartFooterProps) {
  return (
    <div className={styles.footer}>
      {totalItemsQty > 0 && (
        <div className={styles.footerTotalRow}>
          <div className={styles.footerTotalCount}>
            <BodyText fontSize="1.6rem" color="body-3">
              {totalItemsQty}
            </BodyText>
          </div>
          <div className={styles.footerTotalBreakdown}>{cartTotal}</div>
        </div>
      )}
      <div className={styles.footerButtons}>
        {footerButtons.map((Button, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={`button-${index}`}>{Button}</Fragment>
        ))}
      </div>
    </div>
  )
}
