import { createSelector } from '@reduxjs/toolkit'
import getFullName from '@ancon/wildcat-utils/user/getFullName'

import type { RootState } from '../../../store/types'
import { ClientContextState } from '../types'
import isValidPhoneNumber from '../../app/utils/isValidPhoneNumber'

import clientContextCompanyUsersEntityAdapter from './clientContextCompanyUsersEntityAdapter'

function clientContextStateSelector<K extends keyof ClientContextState>(
  state: RootState,
  key: K,
) {
  return state.clientContext[key]
}

// Adapter selectors
const companyUsersAdapterSelectors =
  clientContextCompanyUsersEntityAdapter.getSelectors(
    (state: RootState) => state.clientContext.companyUsers,
  )

export const {
  selectById: clientContextCompanyUserByIdSelector,
  selectAll: clientContextCompanyUsersListSelector,
  selectTotal: clientContextTotalCompanyUsersSelector,
} = companyUsersAdapterSelectors

// Client context selectors
export const clientContextSelector = (state: RootState) =>
  clientContextStateSelector(state, 'clientContext')

export const clientContextFetchPendingSelector = (state: RootState) =>
  clientContextStateSelector(state, 'clientContextFetchPending')

export const clientContextCustomerIdSelector = (state: RootState) =>
  clientContextSelector(state)?.customer?.customerUserId

export const clientContextCustomerPhoneNumberSelector = (state: RootState) =>
  clientContextSelector(state)?.customer?.telephoneNumber

export const clientContextCustomerFirstNameSelector = (state: RootState) =>
  clientContextSelector(state)?.customer?.firstName

// Customer details selectors
export const clientContextCustomerSelector = (state: RootState) =>
  clientContextStateSelector(state, 'customer')

export const clientContextCurrentCompanyUserIdSelector = (state: RootState) =>
  clientContextStateSelector(state, 'currentCompanyUserId')

export const clientContextCustomerEmailSelector = (state: RootState) =>
  clientContextCustomerSelector(state)?.email

export const clientContextCustomerEmailHashSelector = (state: RootState) =>
  clientContextCustomerSelector(state)?.emailHash

export const clientContextCustomerNameSelector = createSelector(
  [
    clientContextCustomerSelector,
    clientContextSelector,
    (state: RootState, fallback?: string) => fallback, // Default to "N/A"
  ],
  (customer, clientContext, fallback) =>
    getFullName(customer || clientContext?.customer, fallback),
)
export const clientContextAuthenticationUserSelector = (state: RootState) =>
  clientContextStateSelector(state, 'clientContext')?.authenticationUser

export const clientContextCurrentCompanyUserSelector = createSelector(
  [clientContextCurrentCompanyUserIdSelector, state => state],
  (companyId, state) =>
    clientContextCompanyUserByIdSelector(state, companyId ?? ''),
)

export const clientContextCurrentUserCompanyNameSelector = createSelector(
  [clientContextCurrentCompanyUserSelector],
  currentCompanyUser => currentCompanyUser?.companyName ?? '',
)

export const clientContextCurrentUserCompanyIdSelector = createSelector(
  [clientContextCurrentCompanyUserSelector],
  currentCompanyUser => currentCompanyUser?.companyId ?? null,
)

export const clientContextHasCompanyProfilesSelector = createSelector(
  [clientContextSelector, clientContextTotalCompanyUsersSelector],
  (clientContext, totalCompanyUsers) =>
    !!clientContext?.isCompany && totalCompanyUsers > 0,
)

export const clientContextIsCompanyUserSelector = createSelector(
  [clientContextCurrentCompanyUserIdSelector],
  currentCompanyUserId => !!currentCompanyUserId,
)

export const clientContextCustomerAddressesSelector = (state: RootState) =>
  clientContextCustomerSelector(state)?.addresses

export const currentCompanyUserDefaultInvoiceReferenceNoteSelector =
  createSelector(
    [clientContextCurrentCompanyUserSelector],
    companyUser => companyUser?.defaultInvoiceReferenceNote || '',
  )

export const clientContextProfileNameSelector = createSelector(
  [
    clientContextIsCompanyUserSelector,
    clientContextCurrentUserCompanyNameSelector,
    clientContextCustomerNameSelector,
  ],
  (isCompanyUser, currentUserCompanyName, customerName) =>
    isCompanyUser ? currentUserCompanyName : customerName,
)

export const clientContextHasProfileNameSelector = createSelector(
  [
    clientContextIsCompanyUserSelector,
    clientContextCurrentUserCompanyNameSelector,
    clientContextCustomerSelector,
    clientContextSelector,
  ],
  (isCompanyUser, currentUserCompanyName, customer, clientContext) =>
    !!(isCompanyUser
      ? currentUserCompanyName
      : customer || clientContext?.customer),
)

export const clientContextCustomerDetailsSelector = createSelector(
  clientContextSelector,
  clientContext => clientContext?.customer,
)

export const clientContextAuthenticationUserSignInProviderSelector =
  createSelector(
    clientContextAuthenticationUserSelector,
    authenticationUser => authenticationUser?.signInProvider,
  )

export const clientContextIsAnonymousCustomerSelector = createSelector(
  clientContextAuthenticationUserSelector,
  authenticationUser =>
    !!authenticationUser?.signInProvider &&
    authenticationUser?.signInProvider === 'anonymous',
)

export const isUserWithIncompleteCustomerDetailsSelector = createSelector(
  clientContextSelector,
  clientContext =>
    !!clientContext?.authenticationUser?.signInProvider &&
    clientContext?.authenticationUser?.signInProvider !== 'anonymous' &&
    (!clientContext?.customer?.email ||
      !isValidPhoneNumber(clientContext?.customer?.telephoneNumber || '')),
)
