import React, { useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import noop from 'lodash/noop'
import { useRouter } from 'next/router'
import InfoIcon from '@ancon/wildcat-ui/shared/icons/info.svg'

import useAppDispatch from '../../../store/hooks/useAppDispatch'
import {
  preOrderClearCheckout,
  setPreOrderInfoModalVisible,
  setPreOrderLeaveModalVisible,
} from '../store/preOrderSlice'
import useAppSelector from '../../../store/hooks/useAppSelector'
import {
  preOrderCheckoutIdSelector,
  preOrderCheckoutOutletIdSelector,
  preOrderCheckoutSelector,
  preOrderCheckoutTotalItemCountSelector,
  preOrderCheckoutUserItemsSelector,
  preOrderConfirmPendingSelector,
  preOrderCurrentCheckoutPendingSelector,
  preOrderUserIsAttendeeSelector,
  preOrderUserIsHostSelector,
} from '../store/preOrderSelectors'
import Button from '../../app/components/Button'
import CartHeader from '../../checkout/components/shoppingCart/CartHeader'
import CartItemList from '../../checkout/components/shoppingCart/CartItemList'
import CartFooter from '../../checkout/components/shoppingCart/CartFooter'
import CartOutOfStockPopup from '../../checkout/components/shoppingCart/CartOutOfStockPopup'
import { updateCheckout } from '../../checkout/store/checkoutThunks'
import { AppRoutes } from '../../app/constants'
import { appSetIsCartDrawerVisible } from '../../app/store/appSlice'
import { confirmPreOrderCheckout } from '../store/preOrderThunks'
import localStorageUtils from '../../app/utils/localStorageUtils'
import useAppStore from '../../../store/hooks/useAppStore'
import useRouteMatch from '../../app/hooks/useRouteMatch'
import { outletSelectedOutletIdSelector } from '../../outlet/store/outletSelector'

import styles from './PreOrderShoppingCart.module.scss'
import PreOrderTotal from './PreOrderTotal'
import LeavePreOrderModal from './LeavePreOrderModal'

export default function PreOrderShoppingCart() {
  const { t } = useTranslation('common')
  const dispatch = useAppDispatch()
  const router = useRouter()
  const store = useAppStore()

  const preOrderCheckout = useAppSelector(preOrderCheckoutSelector)
  const preOrderItems = useAppSelector(preOrderCheckoutUserItemsSelector)
  const preOrderItemsTotalQty = useAppSelector(
    preOrderCheckoutTotalItemCountSelector,
  )
  const preOrderOutletId = useAppSelector(preOrderCheckoutOutletIdSelector)
  const selectedOutletId = useAppSelector(outletSelectedOutletIdSelector)
  const checkoutPending = useAppSelector(preOrderCurrentCheckoutPendingSelector)
  const isConfirmPending = useAppSelector(preOrderConfirmPendingSelector)
  const isHost = useAppSelector(preOrderUserIsHostSelector)
  const isAttendee = useAppSelector(preOrderUserIsAttendeeSelector)

  const isOutletPage = useRouteMatch([`${AppRoutes.Outlet}/`])

  const isPreOrderOutletPage =
    isOutletPage &&
    preOrderOutletId &&
    selectedOutletId &&
    preOrderOutletId === selectedOutletId

  const [checkingOutOfStockItems, setCheckingOutOfStockItems] =
    useState<boolean>(false)

  const { orderFormat, outlet } = preOrderCheckout!

  function handleShowPreOrderInfo() {
    dispatch(setPreOrderInfoModalVisible(true))
  }

  function handleLeavePreOrder() {
    dispatch(setPreOrderLeaveModalVisible(true))
  }

  async function requestCheckOutOfStockItems(callback: () => void) {
    setCheckingOutOfStockItems(true)
    try {
      await dispatch(updateCheckout({})).unwrap()
      callback()
    } catch (err) {
      noop()
    } finally {
      setCheckingOutOfStockItems(false)
    }
  }

  function handleManagePreOrder() {
    router.push(AppRoutes.PreOrderManage)
    dispatch(appSetIsCartDrawerVisible(false))
  }

  async function handleConfirmPreOrder() {
    const preOrderId = preOrderCheckoutIdSelector(store.getState())

    await dispatch(confirmPreOrderCheckout())

    // Attendee no need the cart anymore
    if (isAttendee) {
      dispatch(preOrderClearCheckout())
      localStorageUtils.removeItem('cartMeta')
    }

    // Navigate to status page
    router.push(`${AppRoutes.PreOrderStatus}/${preOrderOutletId}/${preOrderId}`)
  }

  function handleOnManagePreOrderClick() {
    requestCheckOutOfStockItems(handleManagePreOrder)
  }

  function handleOnConfirmPreOrderClick() {
    requestCheckOutOfStockItems(handleConfirmPreOrder)
  }

  function handleContinueOutOfStockPopup() {
    if (isHost) {
      handleManagePreOrder()
    } else {
      handleConfirmPreOrder()
    }
  }

  return (
    <div className={styles.container}>
      <CartHeader
        title={t('preOrder.myPreOrder')}
        outletName={outlet.name}
        orderFormat={orderFormat}
        loading={checkoutPending}
        additionalActions={
          isPreOrderOutletPage && (
            <Button
              variant="secondary"
              onClick={handleShowPreOrderInfo}
              className={styles.preOrderInfoButton}
              outlined
            >
              <InfoIcon />
              <span>{t('preOrder.moreInfo')}</span>
            </Button>
          )
        }
      />
      <CartItemList
        items={preOrderItems}
        canEditItems
        itemDisabled={checkoutPending}
      />
      <CartFooter
        totalItemsQty={preOrderItemsTotalQty}
        cartTotal={
          <PreOrderTotal checkout={preOrderCheckout!} isAttendeeTotal />
        }
        footerButtons={[
          <Button
            onClick={
              isHost
                ? handleOnManagePreOrderClick
                : handleOnConfirmPreOrderClick
            }
            loading={checkingOutOfStockItems || isConfirmPending}
            disabled={isAttendee && preOrderItems.length === 0}
          >
            {isHost ? t('preOrder.managePreOrder') : t('preOrder.confirmOrder')}
          </Button>,
          <Button variant="secondary" onClick={handleLeavePreOrder}>
            {t('preOrder.leavePreOrder')}
          </Button>,
        ]}
      />
      <CartOutOfStockPopup onContinue={handleContinueOutOfStockPopup} />
      <LeavePreOrderModal />
    </div>
  )
}
