import useTranslation from 'next-translate/useTranslation'
import { useFormContext } from 'react-hook-form'
import { CountryCode } from '@ancon/wildcat-types'

import companyConstraints from '../../../company/constraints/companyConstraints'
import FormInput from '../../../app/components/FormInput'
import HeadingText from '../../../app/components/HeadingText'
import { searchOutlets } from '../../../outlets/store/outletsThunks'
import FormDropdownAsync from '../../../app/components/FormDropdownAsync'
import { CompanySignUpFormState } from '../../types'
import FormPhoneNumberInput from '../../../app/components/FormPhoneNumberInput'
import isAppWhiteLabeled from '../../../app/utils/isAppWhiteLabeled'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'

import styles from './CompanySignUpFieldSet.module.scss'

export default function CompanyDetails() {
  const { t } = useTranslation('common')

  const dispatch = useAppDispatch()

  const isWhiteLabeled = isAppWhiteLabeled()

  const { formState, watch } = useFormContext<CompanySignUpFormState>()

  const { isSubmitting } = formState

  const countryCode = watch('country')

  function validateBusinessNumber(value: string) {
    if (countryCode === CountryCode.Sweden) {
      const isValidNumber =
        companyConstraints.pattern.swedenBusinessNumber.test(value)
      return !value || isValidNumber || t('validations.invalid')
    }
    return true
  }

  async function fetchOutletOptions(search: string) {
    const response = await dispatch(
      searchOutlets({
        search,
        limit: 5, // Limit to 5 results
      }),
    ).unwrap()

    return response.items.map(outlet => ({
      ...outlet,
      label: outlet.name,
      value: outlet.id,
    }))
  }

  return (
    <section>
      <HeadingText as="h3" className={styles.fieldSetTitle}>
        {t('companySignUpModal.companyDetails')}
      </HeadingText>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.column}>
            <FormInput
              fieldName="companyName"
              autoComplete="off"
              autoCapitalize="none"
              label={t('companySignUpModal.companyName')}
              type="text"
              rules={{
                required: t('validations.required'),
                maxLength: {
                  value: companyConstraints.max.companyName,
                  message: t('validations.maxLength', {
                    limit: companyConstraints.max.companyName,
                  }),
                },
                minLength: {
                  value: companyConstraints.min.companyName,
                  message: t('validations.maxLength', {
                    limit: companyConstraints.min.companyName,
                  }),
                },
              }}
              readOnly={isSubmitting}
              required
              placeholder={t('companySignUpModal.placeholders.companyName')}
            />
          </div>
          <div className={styles.column}>
            <FormInput
              fieldName="businessNumber"
              autoComplete="off"
              autoCapitalize="none"
              label={t('companySignUpModal.businessNumber')}
              placeholder={t('companySignUpModal.placeholders.businessNumber')}
              type="text"
              rules={{
                required: t('validations.required'),
                maxLength: {
                  value: companyConstraints.max.businessNumber,
                  message: t('validations.maxLength', {
                    limit: companyConstraints.max.businessNumber,
                  }),
                },
                minLength: {
                  value: companyConstraints.min.businessNumber,
                  message: t('validations.minLength', {
                    limit: companyConstraints.min.businessNumber,
                  }),
                },
                validate: validateBusinessNumber,
              }}
              readOnly={isSubmitting}
              required
            />
          </div>
        </div>
        <div className={styles.row}>
          {!isWhiteLabeled && (
            <div className={styles.column}>
              <FormDropdownAsync
                label={t('companySignUpModal.outlet')}
                fieldName="outlet"
                fetchOptions={fetchOutletOptions}
                type="text"
                rules={{
                  required: t('validations.required'),
                }}
                containerClassName={styles.flexContainer}
                required
              />
            </div>
          )}
          <div className={styles.column}>
            <FormPhoneNumberInput
              autoComplete="off"
              fieldName="companyPhoneNumber"
              label={t('companySignUpModal.companyPhoneNumber')}
              readOnly={isSubmitting}
              rules={{
                required: t('validations.required'),
              }}
              required
              placeholder={t(
                'companySignUpModal.placeholders.companyPhoneNumber',
              )}
            />
          </div>
          {isWhiteLabeled && <div className={styles.column} />}
        </div>
      </div>
    </section>
  )
}
